<template>
  <main class="container my-5">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <form @submit.prevent="submitDockerImage">
          <div class="form-group">
            <label for>Docker Image Name</label>
            <input
              v-model="dockerImage.name"
              type="text"
              class="form-control"
              placeholder="Enter Docker Image Name"
              required
            />
          </div>

          <div class="form-group">
            <label for>Docker Common Identifier</label>
            <input
              v-model="dockerImage.common_identifier"
              type="text"
              class="form-control"
              placeholder="Enter Docker Common Identifier"
              required
            />
          </div>

          <div class="form-group">
            <label for>Docker Pull Command</label>
            <input
              v-model="dockerImage.pull_command"
              type="text"
              class="form-control"
              placeholder="Enter Docker Pull Command"
              required
            />
          </div>

          <div class="form-group">
            <label for>Docker Run Command</label>
            <input
              v-model="dockerImage.run_command"
              type="text"
              class="form-control"
              placeholder="Enter Docker Run Command"
              required
            />
          </div>

          <div class="form-group">
            <label for>Docker Service Name</label>
            <input
              v-model="dockerImage.service_name"
              type="text"
              class="form-control"
              placeholder="Enter Docker Service Name"
              required
            />
          </div>

          <div class="form-group pt-2">
            <label for="selectImageType">Image Type</label>
            <select
              v-model="dockerImage.image_type"
              class="form-control"
              id="image_type"
              name="image_type"
              required
            >
              <option value="">Select Image Type</option>
              <option value="DEFAULT">DEFAULT</option>
              <option value="CUSTOM">CUSTOM</option>
            </select>
          </div>

          <div class="d-flex justify-content-center pt-3">
            <button
              class="btn btn-space btn-primary"
              :disabled="submitting"
              type="submit"
            >
              <span
                v-if="submitting"
                class="spinner-border spinner-border-sm"
              ></span>
              {{ submit }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "DockerImageCreationForm",
  data() {
    return {
      dockerImage: {
        name: "",
        common_identifier: "",
        pull_command: "",
        run_command: "",
        service_name: "",
        image_type: "",
      },
      submit: "Submit",
      submitting: false,
      error: "",
    };
  },
  methods: {
    async submitDockerImage() {
      this.submitting = true;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      const formData = new FormData();
      for (const data in this.dockerImage) {
        formData.append(data, this.dockerImage[data]);
      }
      try {
        this.submit = "Adding Docker Image";
        this.submitting == true;
        await axios.post(`v1/docker-images/`, formData, config).then((response) => {
          if (response.status === 201) {
            router.push({
              name: "docker-image-details",
              params: { id: response.data.id },
            });
            this.$swal(" ", "Docker Image Added Successfully", "success");
          }
        });
      } catch (e) {
        this.$swal("Error", `${e.response.data.name[0]}`, "error");
      }finally{
        this.submitting = false;
        this.submit = "Submit";
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'docker-images' }">Docker Images</router-link-active>
          </li>
        </ol>
      </nav>
    </div>
    <DockerImageComponent/>
  </div>
</template>

<script>
import DockerImageComponent from "@/components/DockerImages/DockerImageComponent";

export default {
  components: {DockerImageComponent},
};
</script>

<style></style>

<template>
  <table class="table table-striped table-hover table-fw-widget" id="table5">
    <thead>
    <tr>
      <th>Name</th>
      <th>Common Identifier</th>
      <th>Pull Command</th>
      <th>Run Command</th>
      <th>Service Name</th>
      <th>Type</th>
      <th>Created</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
    </thead>
    <tbody v-if="dockerImages" >
    <tr v-for="dockerImage in dockerImages" :key="dockerImage.id" class="odd gradeX">
      <td>{{ dockerImage.name }}</td>
      <td class="text capitalize">{{ dockerImage.common_identifier }}</td>
      <td class="text capitalize">{{ dockerImage.pull_command }}</td>
      <td class="text capitalize">{{ dockerImage.run_command }}</td>
      <td class="text capitalize">{{ dockerImage.service_name }}</td>
      <td class="text capitalize">{{ dockerImage.image_type }}</td>
      <td>
        {{ new Date(dockerImage.created).toLocaleString() }}
      </td>
      <td>
        <router-link :to="{ name: 'docker-image-details', params: { id: dockerImage.id } }">
          <button class="btn btn-primary">View</button>
        </router-link>
      </td>
      <td>
        <router-link :to="{ name: 'docker-image-edit', params: { id: dockerImage.id } }">
          <button class="btn btn-primary">Edit</button>
        </router-link>
      </td>
      <td>
        <button
            class="btn btn-sm btn-danger"
            data-toggle="modal"
            data-target="#dockerImageModal"
            @click="modalInfo(dockerImage)"
        >
          Delete
        </button>
      </td>
      <td class="center"></td>
    </tr>
    <tr v-if="dockerImages.length === 0">
      <td colspan="5">No Docker Images found.</td>
    </tr>
    </tbody>
    <tbody v-else>
    <tr>
      <td colspan="5">Loading...</td>
    </tr>
    </tbody>
    <div
        id="dockerImageModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dockerImageModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="dockerImageModalLabel" class="modal-title">
              Delete Docker Image
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete {{ selectedDockerImage.name }}?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Dismiss
            </button>
            <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                @click="deleteAPK(selectedDockerImage.id)"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </table>
</template>

<script>
import axios from "axios";

export default {
  name: "DockerImageTable",
  data() {
    return {
      dockerImages: null,
      error: "",
      selectedDockerImage: "",
      loading: true,
    };
  },
  mounted() {
    this.getDockerImages();
  },
  methods: {
    getDockerImages() {
      this.loading = true;
      axios
          .get(`v1/docker-images/`)
          .then((response) => {
            if (response.status === 200) {
              this.dockerImages = response.data;
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("Error", `${error.response.data.detail}`, "error");
            }
          }).finally(() => {
            this.loading = false;
      });
    },
    deleteAPK(id) {
      axios.delete(`v1/docker-images/${id}/`).then((response) => {
        if (response.status === 204) {
          this.$swal("Success", "Docker Image Successfully deleted", "success");
          this.getDockerImages()
          // this.$forceUpdate();
        }
      });
    },
    modalInfo(product) {
      this.selectedDockerImage = product;
    },
  },
};
</script>
